
import { Options, Vue } from "vue-class-component";
import { DexAssessmentController } from "@/app/ui/controllers/DexAssessmentController";

@Options({
  components: {}
})
export default class BulkBooking extends Vue {
  created(): void {
    DexAssessmentController.onResetFilter();
    DexAssessmentController.setStartDate(new Date().toDateString());
    DexAssessmentController.setEndDate(new Date().toDateString());
  }
  // Tabs
  get tabs() {
    return DexAssessmentController.dashboardTabs;
  }
  changeTabs(value: string) {
    DexAssessmentController.onResetFilter();
    DexAssessmentController.setDashboardCurrentTabActive(value);
    this.$router.push({
      name: value
    });
  }
  isTabActive(path: string) {
    return path === this.$route.name;
  }
}
